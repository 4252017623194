<template>
  <div style="background-color:#fff;border-radius:40px;margin:20px;" class="px-4 py-2 box">

    <div
      class="p-2 mt-4 text-sm font-bold"
      style="background-color:#F6F9FE;margin-bottom:15px;"
    >
      基本信息
    </div>
    <div class="">
      <div class="w-1/2"><span style="float:left;margin-left:15px;line-height: 30px;">模型名称： </span>          
         <div  style="float:left;width:117px;height:30px" class="input_box">
               <a-select
              ref="select"
              v-model:value="modeln.model"
              style="width: 117px;height:30px"
              
              @change="handleChange"
              >
              <a-select-option  v-for="(val) in modelTypeList"  :value="val.id">
                {{ val.name }}
              </a-select-option>
                     
              </a-select>
            </div></div>
      <div class="w-1/2"><span style="float:left;margin-left:30px;line-height: 30px;">机器人名称：</span>            <div  style="float:left;width:117px;height:30px" class="input_box">
               <a-select
              ref="select"
              v-model:value="modeln2.model"
              style="width: 117px;height:30px"
              
              @change="handleChange2"
              >
              <a-select-option  v-for="(val) in robotList"  :value="val.id">
                {{ val.name }}
              </a-select-option>
                     
              </a-select>
            </div></div>
    </div>
    <div style="clear:both;"></div>
    <div
      class="p-2 mt-4 text-sm font-bold"
     style="background-color:#F6F9FE;margin-bottom:15px;"
    >
     三维信息
    </div>
    <!-- <div class="flex justify-between">
        <div v-for="(val,key) in supportData">
          <div class="flex justify-between" v-if="(isNaN(val[0])!=true)">
            <span style="width:80px;">{{size_config[key]}}</span>
            
            <a-input type="number" size="small" placeholder="请输入" v-model="SizeC[key]" />
            {{val[0]}}~{{val[val.length-1] }}</div>
          <div class="flex justify-between"  v-if="(isNaN(val[0])==true)">
           
            <div v-for="(valz,indexz) in val">
              
           
              <div v-if="(indexz==0 || indexz==val.length-1)">
                <div  v-for="(valaaa,keyaaa) in valz">
                  <span   v-if="(indexz==0 )" style="width:80px;">{{size_config[keyaaa]}} </span>
                  <a-input v-if="(indexz==0 )" type="number" size="small" placeholder="请输入" v-model="SizeC[keyaaa]" />
                  <span v-if="(indexz==0 )">{{valaaa}}~</span>
                  <span  v-if="(indexz==val.length-1 )">{{valaaa}}</span>
                </div>
              </div>
             
            </div>
          </div>
        </div>

    </div> -->
    <div style="padding-left:20px;" class="">
        <!-- <div v-for="(val,key) in supportData">
          <div class="flex abc" v-if="(isNaN(val[0])!=true)">
            <span style="width:80px;">{{size_config[key]}}</span>
            
            <a-input type="number" size="small" placeholder="请输入" v-model="SizeC[key]" />
            {{val[0]}}~{{val[val.length-1] }}</div>

        </div>
        <div v-if="(supportData.hasOwnProperty('shouder_bust'))" class="flex abc"  >
           
           <span style="width:80px;">肩宽</span>
           <a-input type="number" size="small" placeholder="请输入" v-model="SizeC['shoulder']" />
           {{ supportData.shouder_bust[0].shoulder }}~{{ supportData.shouder_bust[supportData.shouder_bust.length-1].shoulder }}         
         </div>
         <div v-if="(supportData.hasOwnProperty('shouder_bust'))" class="flex abc"  >
          
          <span style="width:80px;">臀围</span>
          <a-input type="number" size="small" placeholder="请输入" v-model="SizeC['bust']" />
          {{ supportData.shouder_bust[0].bust }}~{{ supportData.shouder_bust[supportData.shouder_bust.length-1].bust }}         
        </div> -->
        <div v-for="(val,key) in supportData.size_val_list">
          <div class="flex abc" >
            <span style="width:45px;">{{size_config[key]}}</span>
            
            <a-input-number size="small" :min="val[0]" :max="val[val.length-1]"  placeholder="请输入" v-model="SizeC[key]" />
            <span style="color:#aaa;margin-left:10px;">({{val[0]}}~{{val[val.length-1] }})</span></div>

        </div>
    </div>
    <div style="clear:both;"></div>
    <!-- <a-button 
          type="primary"
          @click="goOk"
          class="mb-2 mr-2 floatleft"
          >确定</a-button> -->
   <!-- <div
      class="flex justify-between p-2 mt-4 text-sm font-bold"
     
    >
       <div>数据上传</div> -->
      <!-- <a
        class="text-red-500"
        style="text-decoration:underline"
        :href="downloadLink"
        download
        >模板下载</a
      >
    </div> -->

    <div
      class="p-2 mt-2 text-sm font-bold"
      style="background-color:#F6F9FE;margin-bottom:15px;"
    >
      设备操作
    </div>
    <a-modal v-model:visible="visible" title="" @ok="robotStar">
      <p>数据传输已成功，是否启动机器人</p>

    </a-modal>
    <div class="flex items-center justify-between mt-2">
      <div>
        <a-button type="primary" @click="connect" class="mb-2 mr-2">{{
          getIsConnect ? "关闭串口" : "打开串口"
        }}</a-button>
         <!-- <a-button type="primary" @click="startgoOk" class="mb-2 mr-2" v-if="getIsConnect">发送</a-button>  -->
        <!-- <a-button
          type="primary"
          @click="autoCreateSubOrderFunc"
          class="mb-2 mr-2"
          >自动生成子订单</a-button
        > -->

      
        <a-button
          type="primary"
          @click="robotReset"
          class="mb-2 mr-2"
          v-if="getIsConnect"
          >机器人复位</a-button
        >
        <a-button
          type="primary"
          @click="startgoOk"
          class="mb-2 mr-2"
          v-if="getIsConnect"
          >机器人开始工作</a-button
        >
        <a-button
          type="primary"
          @click="robotEnd"
          class="mb-2 mr-2"
          v-if="getIsConnect"
          >机器人暂停工作</a-button
        >
      </div>
    </div>
    <div class="p-2 py-3 font-bold rounded bg-gray-50">
      连接状态{{ getIsConnect ? "(已连接)" : "" }}：
      <span :class="getIsConnect ? 'text-primary' : 'text-gray-400'">{{
        getIsConnect ? getEquipmentInfo : "暂未连接串口设备"
      }}</span>
    </div>
    <div
      class="p-2 mt-4 text-sm font-bold"
      style="background-color:#F6F9FE;margin-bottom:15px;"
    >
      数据发送进度
    </div>
    <a-progress
      :stroke-color="{
        from: '#108ee9',
        to: '#87d068',
      }"
      :percent="(getStep / getDataSource.length).toFixed(1) * 100"
      status="active"
    />
    <!-- <div class="pt-4">······
      <a-button type="primary" @click="sendRobotData" class="mr-2">数据入库</a-button>
    </div>-->
    <!-- <div class="pt-4">
      <a-button type="primary" @click="takingPicturesRequest" class="mr-2">拍照</a-button>
    </div>-->
    <!-- <div class="pt-4">
      <div class="py-2 text-base font-bold history">当前形变数据</div>
      <a-table
        :data-source="getCurrentData"
        :columns="columns"
        :scroll="{ x: 1300 }"
      >
      </a-table>
    </div> -->
    <div class="pt-4">
      <!-- <div class="py-2 text-base font-bold history">当前形变数据</div> -->
      <div
      class="p-2 mt-2 text-sm font-bold"
      style="background-color:#F6F9FE;margin-bottom:15px;"
    >
    当前形变数据
    </div>
      <a-table
        :data-source="getDataSource"
        :columns="columns"
        :scroll="{ x: 1300 }"
      >
        <template slot="operation" slot-scope="text, record">
          <div class="flex">
            <a-button
              type="default"
              class="mr-2"
              @click="modifyData(record.key)"
              >修改</a-button
            >
            <a-popconfirm
              v-if="getDataSource.length"
              title="确定删除吗？"
              cancelText="取消"
              okText="确定"
              @confirm="() => deleteData(record.key)"
            >
              <a-button type="danger">删除</a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </div>



  </div>
</template>

<script>
import {

  getModelSupportData,
  getStepValue,
  addRobot,
  //
  ruleRobot,
  //
  robotList,
  //
  modelList,
  changeRobot,
  changeRobotUp,
  deleteRobot,
  operatoinRobot,
  workRobot,
  disableRobot,

  modelTypeList,
  newrobotList,

   newrobotAdd,
   newrobotEdit,
   newrobotInfo,
   newrobotDesabled,
} from "@/api/robot";
import { mapState, mapGetters } from "vuex";
import { createSubOrder, autoCreateSubOrder,getOrderStatus,updateStatus } from "@/api/collect";
import { BASE_URL } from "@/utils/common";
export default {
  data() {
    return {
      zanting:true,
      SizeC:{},
      sendM:'',
      modeln:{name:'',code:'',model:''},
      modeln2:{name:'',code:'',model:''},
      money: "",
      supportData:'',
      getDataSource:[],
      robotList:'',
      visible:false,
      num: "",
      modifyIndex: -1, //  用户修改的数据索引值
      getMessage:'',
      columns: [],
      modelTypeList:'',
      errorMsg:
        "浏览器不支持串口通讯请更换浏览器或升级浏览器版本(谷歌，Edge，欧朋)",
        size_config:{
    "arm":  '上臂围',
 
      "bust":  '胸围',
 
      "height":  '身长' ,
 
      "length" : '上身长',
 
      "neck_circumference":  '颈围',
 
      "shoulder":  '肩宽',
   
      "shoulder_width":  '肩宽',
 
      "shoulder_circumference":  '肩围',
 
     "upper_arm_circumference":  '臂围',
 
     "under_bust":  '胸底围',
 
      "waist": '腰围',
 
      "high_hip":  '跨围',
 
      "low_hip":  '臀围',
 
      "hipline":  '臀围',
 
      "thigh_circumference":  '大腿围',
 
      "thigh_length":  '大腿长',
 
      "calf_circumference":  '小腿围',
 
      "calf_length":  '小腿长',
      'hipheight':'臀高',
      'lower':'下身长'

 
 },
      msgInputObject: {
        length: {
          name: "身高",
          num: 1,
        },

        shoulder: {
          name: "肩宽",
          num: 1,
        },
        arm: {
          name: "上臂围",
          num: 1,
        },
        bust: {
          name: "胸围",
          num: 1,
        },
        waist: {
          name: "腰围",
          num: 1,
        },
        hipline: {
          name: "臀围",
          num: 1,
        },
        hipheight: {
          name: "臀高",
          num: 1,
        },
        lower: {
          name: "下身长",
          num: 1,
        },
        thigh: {
          name: "大腿围",
          num: 1,
        },
        thigh_length: {
          name: "大腿长",
          num: 1,
        },
        calf: {
          name: "小腿围",
          num: 1,
        },
      },

      // 上传的文件的相关属性
      action: "",
      data: {},
      headers: {},

      downloadLink: "", // 模板下载路径
    };
  },
  computed: {
    ...mapGetters([
     // "getDataSource",
      "getInputObject",
      "getIsConnect",
      "getEquipmentInfo",
      "getStep",
      "getOrderInfo",
      "getCurrentData",
    ]),
    ...mapState(["currentData"]),
  },
  watch: {
    // 监听订单的改变
    getOrderInfo: function() {
      this.data.order_id = this.getOrderInfo.id;
    },
    SizeC:{
	
	handler:function(que,queold){
       console.log(que)
        console.log(queold)
//  if(que.bust !=''){
  let linshiarr = []
  for(let iq=0;iq<this.supportData.shoulder_bust.length;iq++){
    
    //console.log(this.supportData.shoulder_bust[iq].shoulder)
    if(parseInt(que.shoulder) == this.supportData.shoulder_bust[iq].shoulder  ){
  //  que.bust= this.supportData.shoulder_bust[iq].bust
        
        linshiarr.push(this.supportData.shoulder_bust[iq].bust)
        
   
    }
  }

  if(linshiarr.length>0){
    let max = Math.max(...linshiarr)
  console.log(max)
  let min = Math.min(...linshiarr)
  console.log(min)
  console.log(isNaN(min))
    this.supportData.size_val_list.bust[0] = min
  this.supportData.size_val_list.bust[this.supportData.size_val_list.bust.length-1] = max
  }
  
// }

     
      },
  deep:true
    }
  },
  created() {
    this.newf()
    this.$EventBus.$on("completeModal", () => {
      this.$confirm({
        title: "提示",
        content: "采集完成",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          console.log("OK");
          this.$router.push("/collectWork");
        },
        onCancel() {
          this.$router.push("/collectWork");
          console.log("Cancel");
        },
        class: "test",
      });
    });

    this.$store.commit("updateCurrentData", { type: "reset" });
    this.$store.commit("dataReset");
    this.action = BASE_URL + "/admin/Order/import";
    this.downloadLink =
      BASE_URL +
      "/static/%E6%9C%BA%E5%99%A8%E4%BA%BA%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx";
    const ACCESS_TOKEN = JSON.parse(localStorage.getItem("ACCESS_TOKEN"));
    this.headers["AdminToken"] = ACCESS_TOKEN.token;
    this.headers["AdminUserId"] = ACCESS_TOKEN.userId;
    let orderInfo = localStorage.getItem("orderInfo")
      ? JSON.parse(localStorage.getItem("orderInfo"))
      : "";
    // if (orderInfo) {
    //   this.$store.commit("setOrderInfo", orderInfo);
    // } else {
    //   this.$message.error("请先选择订单");
    //   setTimeout(() => {
    //     this.$router.push({
    //       name: "index",
    //     });
    //   }, 1000);
    // }

    this.initColumns();
  //  this.robotStar()
    // this.takingPicturesRequest()
  },
  methods: {
    // 文件上传之前
   async  startgoOk(){
    
    console.log(this.SizeC)
    this.SizeC.model_id = this.modeln.model
    this.SizeC.robot_id = this.modeln2.model
   getStepValue(this.SizeC).then((res)=>{
      console.log(res)
      if(res.code ==200){
        this.visible = true;
        this.getDataSource = res.data
        console.log(this.getDataSource)
        this.$store.commit("addDataSource", this.getDataSource);
     
        this.$store.commit("updateStep", 0); // 重置步骤值
  
        console.log(this.getDataSource)
           // this.$store.commit("setSubOrder", this.getDataSource);
           
          this.$store.dispatch("submitData");
          
          

      }else{
        this.$message.error(`请填写正确三维信息`);
      }
    })

    
  
  },

  goOk(){
    this.$store.dispatch("robotStar");
  },
    newf(){
      modelList().then((res)=>{
        console.log(res)
        this.modelTypeList = [];
          this.modelTypeList.push(...res.data.list);
          this.modeln.model = this.modelTypeList[0].id
          getModelSupportData({model_id:this.modelTypeList[0].id}).then((res)=>{
      
            if(res.code ==200){
              this.supportData = res.data
            }
          })
      }).catch((error)=>{
        console.log('eee')
        console.log(error)
      })
      newrobotList().then((res)=>{
        this.robotList = []
        this.robotList.push(...res.data.list);
        this.modeln2.model = this.robotList[0].id
      })
    },
    changeData(){
      getStepValue()
    },
    handleChange(value) {
      console.log(`selected ${value}`);
      this.modeln.model = value;
      getModelSupportData({model_id:value}).then((res)=>{
      
        if(res.code ==200){
        
          this.supportData = res.data
          console.log(this.supportData)
        }
      })

      //console.log(this.threerobt)
      //this.threerobt = nanmoshang
    },
    handleChange2(value) {
      console.log(`selected ${value}`);
      this.modeln2.model = value;


      //console.log(this.threerobt)
      //this.threerobt = nanmoshang
    },
    beforeUpload() {
      if (!this.getIsConnect) {
        console.log("执行了beforeUpload请先连接设备");
        this.$message.error(`请先连接设备`);
        return false;
      }
    },
    // 文件上传
    // handleChange(info) {
    //   console.log(this.$refs.upload);
    //   const status = info.file.status;

    //   if (status === "done") {
    //     let res = info.file.response;
    //     console.log("asdfasdfdsfsdf", res);
    //     if (res.code == 200) {
    //       this.$message.success(`${info.file.name} 上传成功`);
    //       let data = info.file.response.data;
    //       this.$store.commit("addDataSource", data);
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   } else if (status === "error") {
    //     this.$message.error(`${info.file.name} 上传失败`);
    //   }
    // },
    // 测试拍照接口
    takingPicturesRequest() {
      this.$store.dispatch("takingPicturesFunc", { turns: 2, turnNumber: 20 });
    },
    initColumns() {
      this.columns = [];
      this.columns.push({
        title: "步骤值",
        dataIndex: "step",
        width: 75,
      });
      console.log(this.columns);
      // 添加基础数据列
      for (let key in this.msgInputObject) {
        this.columns.push({
          title: this.msgInputObject[key].name,
          dataIndex: key + "",
          width: 80,
        });
        console.log(this.columns);
      }
      // 添加形变数据列
      // for (let key in this.getInputObject) {
      //   if (this.getInputObject[key].title) {
      //     this.columns.push({
      //       title: this.getInputObject[key].title,
      //       dataIndex: key + "",
      //       width: 90,
      //     });
      //     console.log(this.columns);
      //   }
      // }
    },

    // 连接串口
    connect() {
      this.$store.dispatch("connect");
    },

    // 发送数据
    submitData() {
      if (this.getDataSource.length == 0) {
        this.$message.error("请先上传数据");
        return;
      }
      let size_ids = [];
      this.getDataSource.forEach((item) => {
        size_ids.push(item.size_id * 1);
      });
      alert('cuowu')
      createSubOrder({
        size_ids,
        order_id: this.getOrderInfo.id,
      })
        .then((res) => {
          console.log("创建子订单", res);
          if (res.code == 200) {
            console.log(res.data);
            this.$store.commit("updateStep", 0); // 重置步骤值
            this.$store.commit("setSubOrder", res.data);
            this.$store.dispatch("submitData");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    // 自动生成子订单
    autoCreateSubOrderFunc() {
      autoCreateSubOrder({ order_id: this.getOrderInfo.id })
        .then((res) => {
          console.log("创建子订单", res);
          if (res.code == 200) {
            console.log(res.data);
            this.$store.commit("addDataSource", res.data);
            this.$store.commit("updateStep", 0); // 重置步骤值
            this.$store.commit("setSubOrder", res.data);
            this.$store.dispatch("submitData");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },

    // 机器人复位
    robotReset() {
      this.$store.dispatch("robotReset");
    },
    // 机器人开始工作
  async  robotStar(id) {
    this.visible = false
    await this.goOk()
    //await this.handleOk()
  //   let wwwid = localStorage.getItem(
  //           "orderInfo").order_id
  //   console.log(localStorage.getItem(
  //           "orderInfo"))
  //      let ttt =     JSON.parse(localStorage.getItem(
  //           "orderInfo"))
  //  // alert(ttt.order_id)
  //   await  getOrderStatus({order_id:ttt.order_id}).then((res)=>{
     
  //     console.log(res)
  //       if(res.code ==200){
  //         switch(res.data.order_status){
  //           case -1 : 
  //            this.visible = true
  //            this.getMessage ='该订单已经删除，是否继续执行'
  //            break;
  //            case 0 : this.handleOk()
  //            break;
  //            case 1 :
  //            this.visible = true
  //            this.getMessage ='该订单已取消，是否继续执行'
  //            break;
  //            case 2 :
  //            this.visible = true
  //            this.getMessage ='该订单已采集，是否继续执行'
  //            break;
  //            case 3 : 
  //            this.visible = true
  //            this.getMessage ='该订单采集中，是否继续执行'
  //            break;
  //            case 4 : 
  //            this.visible = true
  //            this.getMessage ='该订单已上传，是否继续执行'
  //            break;

  //         }
  //       }
  //     })

      //原来的开始工作
     // this.$store.dispatch("robotStar");
      //原来的开始工作
    },
   async handleOk(){
      this.visible = false
      // let ttt =     JSON.parse(localStorage.getItem(
      //       "orderInfo"))
      // updateStatus({order_id:ttt.order_id,order_status:3}).then((res)=>{
        this.$store.commit("updateStep", 0); // 重置步骤值
        alert('aa')
        console.log(this.getDataSource)
            this.$store.commit("setSubOrder", this.getDataSource);
            this.$store.dispatch("submitData");
            alert('bb')
      // })
    console.log('start')
   //   this.$store.dispatch("robotStar");
      console.log('end')
    },
    // 机器人暂停工作
    robotEnd() {
      this.$store.dispatch("robotPause");
    },
  },
};
</script>

<style lang="less" scoped>
.abc{margin-right:20px;width:200px;float:left;}
.floatleft{float:left;margin:20px 20px 0px 0px;}
.item {
  width: 280px;
}
.item div:nth-child(1) {
  width: 70px;
}
.box {
  user-select: none;
}
</style>
